// Select2
$('.select2').select2({
    minimumResultsForSearch: 10
});

$('.select2-100').select2({
    width: "100%",
    minimumResultsForSearch: 10
});

$('.select2-50').select2({
    width: "50%",
    minimumResultsForSearch: 10
});

$('.select2-style').select2({
    width: "style",
    minimumResultsForSearch: 10
});

$(".select2-all").on("click",function(e){
    e.preventDefault();
    var select = $(this).parent().prev().prev();
    $(select).find('option').prop("selected","selected");
    $(select).trigger("change");
});

