$(function () {
    var date = new Date();

    if(document.getElementById("form_long_term_rent")){
        window.datepickerLightpick = new Lightpick({
            field: document.getElementById('start_date'),
            secondField: document.getElementById('end_date'),
            minDate: date,
            format: 'DD / MM / YYYY',
            singleDate: false,
            onClose: function () {
                const event = new Event('lightpickClosed');

                document.dispatchEvent(event);
            }
        });
    }
});
