function alegeAsigurare(name){
    var isChecked = jQuery("#"+name).is(":checked");
    jQuery("[data-asigurare]").removeClass('active');
    var btns = jQuery(".btn-asigurare");
    btns.removeClass('btn-yellow');
    btns.addClass('btn-primary');
    if(isChecked){
        jQuery("[data-asigurare='"+name+"']").addClass('active');
        var input_name = jQuery("#"+name).attr('name');
        jQuery.each(jQuery("input[name='"+input_name+"']").filter(":not(#"+name+")"), function(elem_index, elem_value){
            elem_value.checked = false;
        });
        var _this_btn = jQuery(".btn-asigurare[for='"+name+"']");
        _this_btn.removeClass("btn-primary");
        _this_btn.addClass("btn-yellow");
        console.log(_this_btn);
    }else{
        jQuery("[data-asigurare='"+name+"']").removeClass('active');
    }
}
window.alegeAsigurare = alegeAsigurare;

jQuery("body").on('click', '[data-fade]', (ev) => {
    var $elem = jQuery(ev.target); console.log($elem);
    var _selector = $elem.data('fade');
    var $elems = jQuery(_selector);

    console.log($elems);

    if($elems.filter(":visible").length > 0){
        $elems.fadeOut();
    }else{
        $elems.fadeIn();
    }
})


window.scrollToAnchor = function(id) {
    jQuery("html,body").stop().animate({
            'scrollTop': jQuery("#"+id).offset().top
        }, 900, 'swing', function () {
    });
}

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    resolution: function() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() || isMobile.resolution());
    }
};
