window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');

require('slick-carousel');

require('jquery-countdown')

require('select2');

require('lity')

require('lightpick')

window.moment = require('moment');

import AirDatepicker from 'air-datepicker'
import localeRO from 'air-datepicker/locale/ro';
import localeEN from 'air-datepicker/locale/en';

window.AirDatepicker = AirDatepicker;
window.localeRO = localeRO;
window.localeEN = localeEN;

import Lightpick from 'lightpick';
window.Lightpick = Lightpick;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
